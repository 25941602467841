<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" centered :title="title">
        <b-form @submit.prevent="confirm">
            <div class="mb-4 text-center">
                <label v-b-tooltip.hover="'Connectez-vous à nouveau pour terminer cette action'">
                    Mot de passe &middot;
                    <small>
                        <font-awesome-icon icon="question-circle"/>
                    </small>
                </label>
                <b-form-input v-model="password" type="password" required autocomplete="current-password"/>
            </div>

            <div class="text-center">
                <b-button variant="primary" type="submit">
                    <font-awesome-icon icon="check"/>
                    Confirmer
                </b-button>
                <b-button variant="secondary" @click="hideModal">
                    <font-awesome-icon icon="times"/>
                    Annuler
                </b-button>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
    import modalMixin from '@/mixin/modalMixin';

    export default {
        name: "PasswordConfirmModal",
        mixins: [modalMixin],
        props: {
            title: {
                type: String,
                required: true
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            password: null,
            modalRef: 'passwordConfirm'
        }),
        methods: {
            confirm() {
                this.hideModal();
                this.callback(this.password);
            }
        }
    }
</script>
